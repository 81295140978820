<h1>
    <fa-icon [icon]="faGem"></fa-icon>Паспорта технологий
</h1>

<mat-toolbar>
    <span>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="GetAll()" matTooltip="Обновить содержимое страницы">
            <mat-icon>refresh</mat-icon>
        </button>
    </span>

    <span>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
            [disabled]="parent_data===undefined" matTooltip="Добавить содержимое страницы">
            <mat-icon>add</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="FunctionCreateStructure()">
                <fa-icon [icon]="faGem"></fa-icon>
                <span style="margin-left: 10px;">Структура</span>
            </button>
            <button mat-menu-item [disabled]="selected_view_mode.TypeElement==='TemplatePassport'"
                (click)="FunctionCreateEntity()">
                <fa-icon [icon]="faJetFighter"></fa-icon>
                <span style="margin-left: 10px;">Паспорт</span>
            </button>
            <button mat-menu-item [disabled]="selected_view_mode.TypeElement!='TemplatePassport'"
                (click)="FunctionCreateTemplate()">
                <fa-icon [icon]="faFile"></fa-icon>
                <span style="margin-left: 10px;">Шаблон</span>
            </button>
        </mat-menu>
    </span>

    <span class="flexExpand"></span>

    <span>
        <h3>Отображать:</h3>
    </span>
    <span>
        <mat-select [(ngModel)]="selected_view_mode" name="view_mode" (selectionChange)="change_view_mode($event)">
            <mat-option *ngFor="let mode of view_modes" [value]="mode">
                {{mode.ShortTitle}}
            </mat-option>
        </mat-select>

    </span>

    <span>
        <mat-slide-toggle class="example-margin" [(ngModel)]="show_archieved" (change)="GetAll()">Показать архивные
        </mat-slide-toggle>
    </span>

</mat-toolbar>

<hr>

<div class="mat-elevation-z8" >

    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput placeholder="Filter" (keyup)="Filterchange($event)">
    </mat-form-field>

    <table mat-table matSort [dataSource]="DataSourcePassport" class="mat-elevation-z8">

        <!-- ID Column -->
        <ng-container matColumnDef="ID">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
        </ng-container>

        <!-- TypeElement Column -->
        <ng-container matColumnDef="TypeElement">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Тип</th>
            <td mat-cell *matCellDef="let element">
                <fa-icon *ngIf="element.TypeElement === 'Structure'" [icon]="faGem"></fa-icon>
                <fa-icon *ngIf="element.TypeElement === 'TechnologyPassport'" [icon]="faJetFighter"></fa-icon>
                <fa-icon *ngIf="element.TypeElement === 'TemplatePassport'" [icon]="faFile"></fa-icon>
            </td>
        </ng-container>

        <!-- ShortTitle Column -->
        <ng-container matColumnDef="ShortTitle">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Логин</th>
            <td mat-cell *matCellDef="let element"> {{element.ShortTitle}} </td>
        </ng-container>

        <!-- Security Level -->
        <ng-container matColumnDef="SecurityLevel">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                <fa-icon [icon]="faShieldHalved"></fa-icon>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.SecurityLevel}} </td>
        </ng-container>

        <ng-container matColumnDef="ArchiveSign">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                <fa-icon [icon]="faBoxArchive" matTooltip="Архивировать элемент"></fa-icon>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox class="example-margin" [(ngModel)]="element.ArchiveSign"
                              (change)="FunctionChangeActive(element)" matTooltip="Архивировать элемент">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                <fa-icon [icon]="faPen" matTooltip="Редактировать элемент"></fa-icon>
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;">
                <button (click)="FunctionEdit(element)" mat-button matTooltip="Редактировать элемент">
                    <mat-icon style="color: darkred; font-size: 18px;">edit</mat-icon>
                </button>             
            </td>
        </ng-container>
       
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr (click)="getrow(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5,10,25,100]" showFirstLastButtons>

    </mat-paginator>

</div>
