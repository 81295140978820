<h1>
  <fa-icon [icon]="faUser"></fa-icon>Пользователи
</h1>

<mat-toolbar>

  <span>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="GetAll()" matTooltip="Обновить содержимое страницы">
      <mat-icon>refresh</mat-icon>
    </button>
  </span>

  <span>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
      (click)="FunctionCreate()" matTooltip="Добавить содержимое страницы">
      <mat-icon>add</mat-icon>
    </button>
  </span>


  <span class="flexExpand"></span>

  <span>
    <mat-slide-toggle class="example-margin" [(ngModel)]="show_archieved" (change)="GetAll()">Показать архивные
    </mat-slide-toggle>
  </span>

</mat-toolbar>

<hr>

<!--
<button (click)="FunctionCreate()" mat-raised-button color="primary">
    +
</button>
-->


<!--
<button (click)="FunctionCreate()" mat-raised-button color="warn">
  <fa-icon [icon]="faBoxArchive"></fa-icon>
  Показать архивные
</button>
-->

<div class="mat-elevation-z8">

  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput placeholder="Filter" (keyup)="Filterchange($event)">
  </mat-form-field>

  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- ID Column -->
    <ng-container matColumnDef="ID">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
    </ng-container>

    <!-- login Column -->
    <ng-container matColumnDef="Login">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Логин</th>
      <td mat-cell *matCellDef="let element"> {{element.Login}} </td>
    </ng-container>

    <!-- firstname Column -->
    <ng-container matColumnDef="FirstName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Имя</th>
      <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
    </ng-container>

    <!-- middle Column -->
    <ng-container matColumnDef="MiddleName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Отчество</th>
      <td mat-cell *matCellDef="let element"> {{element.MiddleName}} </td>
    </ng-container>

    <!-- lastname Column -->
    <ng-container matColumnDef="LastName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Фамилия</th>
      <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
    </ng-container>

    <!-- organization Column
      <ng-container matColumnDef="organization">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Организация</th>
        <td mat-cell *matCellDef="let element"> {{element.organization}} </td>
      </ng-container> -->

    <!-- mail Column-->
    <ng-container matColumnDef="Email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <fa-icon [icon]="faEnvelope"></fa-icon>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
    </ng-container>

    <!-- phone Column-->
    <ng-container matColumnDef="Phone">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <fa-icon [icon]="faPhone"></fa-icon>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.Phone}} </td>
    </ng-container>

    <!-- Security Level -->
    <ng-container matColumnDef="SecurityLevel">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        <fa-icon [icon]="faShieldHalved"></fa-icon>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.SecurityLevel}} </td>
    </ng-container>

    <!-- active Column 
      checked="{{element.active}}"
      -->
      <ng-container matColumnDef="ArchiveSign">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <fa-icon [icon]="faBoxArchive" matTooltip="Архивировать элемент" aria-label="Архивировать элемент"></fa-icon>
        </th>
        <td mat-cell *matCellDef="let element" matTooltip="Архивировать элемент" aria-label="Архивировать элемент">
          <mat-checkbox class="example-margin" [(ngModel)]="element.ArchiveSign" (change)="FunctionChangeActive(element)"></mat-checkbox>
        </td>
      </ng-container>
      
      <!--Function Edit-->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">
          <fa-icon [icon]="faPen" matTooltip="Редактировать элемент" aria-label="Редактировать элемент"></fa-icon>
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          <button (click)="FunctionEdit(element)" mat-button style="font-size: 14px; color: darkred;" matTooltip="Редактировать элемент" aria-label="Редактировать элемент">
            <i class="material-icons" style="font-size: 18px;">edit</i>
          </button>
        </td>
      </ng-container>
      
      
      
      
      

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr (click)="getrow(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5,10,25,100]" showFirstLastButtons>

  </mat-paginator>

</div>