import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mbeb',
  templateUrl: './mbeb.component.html',
  styleUrls: ['./mbeb.component.css']
})
export class MbebComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
