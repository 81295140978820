<title mat-dialog-title>Новый проект</title>
<mat-form-field [formGroup]="form" appearance="fill">
    <mat-label>Уровень доступа</mat-label>
    <mat-select formControlName="security_level">
        <mat-option *ngFor="let security_level of security_levels" [value]="security_level">{{security_level.ShortTitle}}</mat-option>
    </mat-select>
</mat-form-field>
<br>

<mat-form-field [formGroup]="form" class="example-full-width">
    <mat-label>Краткое описание</mat-label>
    <input name="st_name" class="form-control" minlenght=1 formControlName="short_title" matInput placeholder="Short title" value="" required>
</mat-form-field>

<br>

<mat-form-field [formGroup]="form" class="example-full-width">
    <mat-label>Исполнитель</mat-label>
    <input formControlName="executor" matInput placeholder="Executor" value="" required>
</mat-form-field>
<br>

<mat-form-field [formGroup]="form" class="example-full-width">
    <mat-label>Заказчик</mat-label>
    <input formControlName="customer" matInput placeholder="Customer" value="" required>
</mat-form-field>
<br>

<mat-form-field [formGroup]="form" class="example-full-width">
    <mat-label>Описание</mat-label>
    <textarea cdkTextareaAutosize formControlName="description" matInput placeholder="Description" value="" required></textarea>
</mat-form-field>
<br>


<mat-form-field appearance="fill">


    <mat-label>Изменение дат</mat-label>
    <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>

    <mat-hint>м/д/г – м/д/г</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

</mat-form-field>


<mat-dialog-actions>
    <button style="margin-right:5.5rem;" class="mat-raised-button" (click)="close()">Закрыть</button>
    <button class="mat-raised-button mat-primary" (click)="save()" [disabled]="form.get('security_level').value.ID === -1 || form.get('short_title').value.length < 1 || form.get('executor').value.length < 1 || form.get('customer').value.length < 1 || form.get('description').value.length < 1 || !form.get('start').value || !form.get('end').value">Сохранить</button>
</mat-dialog-actions>