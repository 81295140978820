<form [formGroup]="project_edit_form" (ngSubmit)="onSubmit()" autocomplete="off">

    <div style="align-items: center;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title">Создать проект</h2>
                </div>
                <div class="modal-body">
                    <div style="min-height: 300px;max-height: 300px; max-width: 430px; padding: 15px; margin: 0 auto;">

                        <div class="form-group">
                            <label>Название</label>
                            <input type="text" formControlName="ShortTitle" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f['ShortTitle'].errors }" />
                            <div *ngIf="submitted && f['ShortTitle'].errors" class="invalid-feedback">
                                <div *ngIf="f['ShortTitle'].errors['required']">Поле не может быть пустым
                                </div>
                                <div *ngIf="f['ShortTitle'].errors['pattern']">Допускаются только латинские буквы и
                                    цифры
                                </div>
                            </div>
                        </div>

                        <!--Description -->
                        <div class="form-group">
                            <label>Описание</label>
                            <textarea name="Description" cols="40" rows="5" formControlName="Description" class="form-control"></textarea>                            
                        </div>

                        <!--SecurityLevel-->
                        <div class="form-group">
                            <label>Уровень секретности</label>
                            <select class="form-control" (change)="changeLevel($event)" formControlName="SecurityLevel">
                                <option *ngFor="let level of security_data" [ngValue]="level.ShortTitle">
                                    {{ level.ShortTitle }}
                                </option>
                            </select>
                        </div>


                    </div>
                </div>


                <div class="modal-footer" style="align-items: center;">
                    <div class="form-group">
                        <button mat-button mat-dialog-close class="btn btn-danger">Отмена</button>
                        <button mat-button type="submit" class="btn btn-primary">Сохранить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>