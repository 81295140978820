<h1> <mat-icon class="mat-icon-rtl-mirror">
    collections
</mat-icon>
Информация о группе
</h1>

<div class="mat-elevation-z8">

    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput placeholder="Filter" (keyup)="Filterchange($event)">
    </mat-form-field>

    <table mat-table matSort [dataSource]="dataSourceGroups" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- ID Column -->
        <ng-container matColumnDef="ID">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
        </ng-container>

        <!-- ShortTitle Column -->
        <ng-container matColumnDef="ShortTitle">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Название</th>
            <td mat-cell *matCellDef="let element"> {{element.ShortTitle}} </td>
        </ng-container>


        <!-- Description Column -->
        <ng-container matColumnDef="Description">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Описание</th>
            <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
        </ng-container>

        <!-- Security Level -->
        <ng-container matColumnDef="SecurityLevel">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                <fa-icon [icon]="faShieldHalved"></fa-icon>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.SecurityLevel}} </td>
        </ng-container>

        <ng-container matColumnDef="ArchiveSign">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                <fa-icon [icon]="faBoxArchive"></fa-icon>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox class="example-margin" [(ngModel)]="element.ArchiveSign" disabled></mat-checkbox>
                <!--
                            (change)="FunctionChangeActive(element)"
                        -->
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr (click)="getrow(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5,10,25,100]" showFirstLastButtons>

    </mat-paginator>

</div>
