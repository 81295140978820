import { NgModule } from "@angular/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatChipsModule } from '@angular/material/chips';
//import { MatLegacyChipsModule } from '@angular/leg';

import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
//import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';

import { OverlayModule } from '@angular/cdk/overlay';
import { CdkMenuModule } from '@angular/cdk/menu';
import { DialogModule } from '@angular/cdk/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { CdkTreeModule } from '@angular/cdk/tree';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
//
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';

import {    MatListModule  } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';

import {MatStepperModule} from '@angular/material/stepper';
import {CdkStepperModule} from '@angular/cdk/stepper';

@NgModule({
    exports: [
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatChipsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        // MatDialog,
        //MatDialogRef
        MatDialogModule,
        //
        OverlayModule,
        CdkMenuModule,
        DialogModule,
        MatSnackBarModule,
        CdkTreeModule,
        CdkStepperModule,
        MatStepperModule,
        MatTreeModule,
        MatIconModule,
        MatSidenavModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRippleModule,
        MatMenuModule,
        MatGridListModule,
        MatTabsModule,
        MatSelectModule,
        MatTooltipModule,
        MatToolbarModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatListModule,
        MatCardModule
        
    ]
})
export class MaterialModule {

}