<div style="height: 92vh;">


    <div style="width: 100%; height: 92%;">
        <h1>Добавление паспортов технологий в реестр КНТП</h1>

        <as-split direction="horizontal">
            <as-split-area [size]="20">

                <mat-tree #treeSelectorOntologyRoot [dataSource]="dataSourceRoot" [treeControl]="treeControlRoot">
                    <!-- This is the tree node template for leaf nodes
                    style="background-color: grey;"
                    -->
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding style="cursor: pointer;"
                        [ngStyle]="{'background-color': (node.ID===selected_row_root.ID) ? 'Lavender' : 'white' }"
                        (click)="click_up_tree_root(node)">
                        <!-- use a disabled button to provide padding for tree leaf -->
                        <button mat-icon-button disabled></button>
                        {{node.name}}
                    </mat-tree-node>
                    <!-- This is the tree node template for expandable nodes -->
                    <mat-tree-node *matTreeNodeDef="let node;when: hasChildRoot" matTreeNodePadding
                        style="cursor: pointer;"
                        [ngStyle]="{'background-color': (node.ID===selected_row_root.ID) ? 'Lavender' : 'white' }"
                        (click)="click_up_tree_root(node)">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
                            (click)="click_up_node_root(node)">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControlRoot.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        {{node.name}}
                    </mat-tree-node>
                </mat-tree>

            </as-split-area>

            <as-split-area [size]="80">

                <mat-toolbar>
                    <span>
                        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
                            (click)="GetRegistryTable()">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </span>

                    <span>                        
                        <button mat-raised-button [disabled]="selected_registry_array.length===0"
                            (click)="AddPassports()">
                            Добавить паспортов, шт.: {{selected_registry_array.length}}
                        </button>
                    </span>

                    <span class="flexExpand"></span>

                    <span>
                        <mat-slide-toggle class="example-margin" [(ngModel)]="show_archieved"
                            (change)="GetRegistryTable()">Показать
                            архивные</mat-slide-toggle>
                    </span>
                </mat-toolbar>

                <mat-toolbar>

                    <span *ngFor="let item of selected_registry_array">
                        <button mat-raised-button color="primary" (click)="RemoveItemFromList(item)">
                            <span>{{item.ShortTitle}}</span>
                            <span><mat-icon>delete_forever</mat-icon></span>
                        </button>
                    </span>

                </mat-toolbar>
                <mat-divider></mat-divider>




                <!--------RegistryList table-->
                <div class="mat-elevation-z8">
                    <mat-form-field>
                        <mat-label>Filter</mat-label>
                        <input matInput placeholder="Filter" (keyup)="Filterchange($event)">
                    </mat-form-field>

                    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">

                        <!-- Checked Column -->
                        <ng-container matColumnDef="Checked">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef><mat-icon>check_box</mat-icon></th>
                            <td mat-cell *matCellDef="let element_table">
                                <mat-checkbox class="example-margin"
                                    (change)="FunctionChecked($event, element_table)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- ID Column -->
                        <ng-container matColumnDef="ID">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
                            <td mat-cell *matCellDef="let element_table"> {{element_table.ID}} </td>
                        </ng-container>

                        <!-- ShortTitle -->
                        <ng-container matColumnDef="ShortTitle">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                Название
                            </th>
                            <td mat-cell *matCellDef="let element_table"> {{element_table.ShortTitle}} </td>
                        </ng-container>

                        <!-- SecurityLevel Column -->
                        <ng-container matColumnDef="SecurityLevel">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                <fa-icon [icon]="faShieldHalved"></fa-icon>
                            </th>
                            <td mat-cell *matCellDef="let element_table"> {{element_table.SecurityLevel}} </td>
                        </ng-container>

                        <!-- ArchiveSign Column -->
                        <ng-container matColumnDef="ArchiveSign">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                <fa-icon [icon]="faBoxArchive"></fa-icon>
                            </th>
                            <td mat-cell *matCellDef="let element_table">
                                <mat-checkbox class="example-margin" [(ngModel)]="element_table.ArchiveSign"
                                    [disabled]="true"></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr (click)="getrow(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5,10,25,100]" showFirstLastButtons>

                    </mat-paginator>

                </div>
                
            </as-split-area>

        </as-split>
    </div>


</div>