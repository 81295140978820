<button style="margin-top: 2rem;" mat-raised-button color="primary" (click)="openStageAddDialog()">
  Добавить этап
</button>

<table style="width: 100%;" matSort mat-table [dataSource]="stages" multiTemplateDataRows class="mat-elevation-z8">

    <ng-container matColumnDef="ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
    </ng-container>

    <ng-container matColumnDef="ShortTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Краткое описание </th>
        <td mat-cell *matCellDef="let element"> {{element.ShortTitle}} </td>
    </ng-container>

    <ng-container matColumnDef="StartDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Дата начала </th>
        <td mat-cell *matCellDef="let element"> {{element.StartDate.slice(0, 10)}} </td>
    </ng-container>

    <ng-container matColumnDef="EndDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Дата окончания </th>
        <td mat-cell *matCellDef="let element"> {{element.EndDate.slice(0, 10) }} </td>
    </ng-container>

    <ng-container matColumnDef="EntityStructure">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Структура Сущности </th>
        <td mat-cell *matCellDef="let element"> {{element.EntityStructure}} </td>
    </ng-container>

    <ng-container matColumnDef="Entities">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Сущность </th>
        <td mat-cell *matCellDef="let element"> {{element.Entities}} </td>
    </ng-container>

    <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Редактирование</th>
        <td (click)="openSatgeEditDialog(element)" mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="openSatgeEditDialog(element)">
                <mat-icon style="font-size: 18px; color: #800000;">edit</mat-icon>
            </button>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="Delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Удалить</th>
        <td mat-cell *matCellDef="let element" (click)="delete(element)">
            <mat-icon>remove</mat-icon>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let element; columns: columns;" class="example-element-row">
    </tr>

</table>