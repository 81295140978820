<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
    <span *ngFor="let child of items">

      
      <!-- Handle branch node menu items mat-menu-item color="primary" -->
      <span *ngIf="child.children && child.children.length > 0">
        <button mat-menu-item [matMenuTriggerFor]="menu.childMenu" class="sidenav_menu_item">
          <mat-icon>{{child.iconName}}</mat-icon>
          <span>{{child.ShortTitle}}</span>
        </button>
        <app-menu-item #menu [items]="child.children" ></app-menu-item>
      </span>
<!--
  (CloseSideMenuEvent)="sidenav.close()"
-->

      <!-- Handle leaf node menu items -->
      <span *ngIf="!child.children || child.children.length === 0">
        <button mat-menu-item (click)="click_menu(child.route)" class="sidenav_menu_item">
          <!--
            [routerLink]="child.route"
          -->
          <mat-icon>{{child.iconName}}</mat-icon>
          <span>{{child.ShortTitle}}</span>
        </button>
      </span>
    </span>
  </mat-menu>
  