<div style="height: 94vh;">


    <div style="width: 100%; height: 100%;">
        <as-split direction="horizontal">
            <as-split-area [size]="20">


                <mat-tree #treeSelectorOntologyRoot [dataSource]="dataSourceRoot" [treeControl]="treeControlRoot">
                    <!-- This is the tree node template for leaf nodes
                    style="background-color: grey;"
                    -->
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding style="cursor: pointer;"
                        [ngStyle]="{'background-color': (node.ID===selected_row_root.ID) ? 'Lavender' : 'white' }"
                        (click)="click_up_tree_root(node)">
                        <!-- use a disabled button to provide padding for tree leaf -->
                        <button mat-icon-button disabled></button>
                        {{node.name}}
                    </mat-tree-node>
                    <!-- This is the tree node template for expandable nodes -->
                    <mat-tree-node *matTreeNodeDef="let node;when: hasChildRoot" matTreeNodePadding style="cursor: pointer;"
                        [ngStyle]="{'background-color': (node.ID===selected_row_root.ID) ? 'Lavender' : 'white' }"
                        (click)="click_up_tree_root(node)">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
                            (click)="click_up_node_root(node)">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControlRoot.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        {{node.name}}
                    </mat-tree-node>
                </mat-tree>

            </as-split-area>

            <as-split-area [size]="80">

                <app-technology-passport-list #child [parent_data]="node_data" (UpdateEvent)="UpdateRoot($event)"></app-technology-passport-list> 

            </as-split-area>

        </as-split>
    </div>


</div>