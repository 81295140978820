<!--<form (ngSubmit)="onSubmit()" autocomplete="off">-->

<div style="align-items: center;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Загрузить изображение</h2>
      </div>
      <div class="modal-body">

        <div style="min-height: 420px;max-height: 420px; max-width: 430px; padding: 15px; margin: 0 auto;">
          <!---->
          <div class="row">
            <div class="col-8">
              <label class="btn btn-default p-0">
                <input type="file" accept="image/*" (change)="selectFile($event)" />
              </label>
            </div>


          </div>

          <div>
            <img [src]="preview" class="preview" style="max-width: 100%; max-height: 100%;">
          </div>

          <div *ngIf="currentFile && progress" class="progress my-3">
            <div class="progress-bar progress-bar-info" role="progressbar" attr.aria-valuenow="{{ progress }}"
              aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: progress + '%' }">
              {{ progress }}%
            </div>
          </div>

          <div *ngIf="message" class="alert alert-secondary" role="alert">
            {{ message }}
          </div>

          <div class="card mt-3">
            <div class="card-header"></div>
            <ul class="list-group list-group-flush">
              <li *ngFor="let image of imageInfos | async" class="list-group-item">
                <p><a href="{{ image.url }}">{{ image.name }}</a></p>
                <img src="{{ image.url }}" alt="{{ image.name }}" height="80px" />
              </li>
            </ul>
          </div>

          <div class="modal-footer" style="align-items: center;">
            <div class="form-group">
              <!--
              <button class="loat-right" mat-button [disabled]="!selectedFiles" (click)="upload()">Загрузить </button>
              <button class="float-left" mat-button mat-dialog-close type="submit">Закрыть</button>
            -->
              <button mat-raised-button color="primary" [disabled]="!selectedFiles" (click)="upload()">Загрузить</button>
              <button mat-raised-button color="warn" (click)="onSubmit()">Отмена</button>

              <!--
              <button mat-button mat-dialog-close class="btn btn-danger">Отмена</button>
              <button mat-button type="submit" class="btn btn-primary">Сохранить</button>
            -->
            </div>
          </div>




          <!--
                        <div class="text-center">
                            <input class="form-control" type="file" (change)="onChange($event)">

                            <button (click)="onUpload()" class="btn btn-success">
                                Upload
                            </button>
                        </div>

                        <div class="container text-center jumbotron" *ngIf="shortLink">
                            <h2> Visit Here</h2>
                            <a href="{{shortLink}}">{{shortLink}}</a>
                        </div>

                        <div class="container" *ngIf="loading">
                            <h3>Loading ...</h3>
                        </div>
                        -->
        </div>
      </div>


      <!--<div class="modal-footer" style="align-items: center;">
                    <div class="form-group">
                        <button mat-button mat-dialog-close class="btn btn-danger">Отмена</button>
                        <button mat-button type="submit" class="btn btn-primary">Сохранить</button>
                    </div>
                </div>-->
    </div>
  </div>
</div>

<!--</form>-->


<!--
<div class="text-center">
    <input class="form-control" type="file" 
            (change)="onChange($event)">
  
    <button (click)="onUpload()" 
        class="btn btn-success">
        Upload
    </button>
</div>
  
Shareable short link of  uploaded file 
<div class="container text-center jumbotron"
    *ngIf="shortLink">
    <h2> Visit Here</h2>
    <a href="{{shortLink}}">{{shortLink}}</a>
</div>
  
Flag variable is used here
<div class="container" *ngIf="loading">
    <h3>Loading ...</h3>
</div>

-->