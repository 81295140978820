<!--
    style="position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);"
-->

<!--
style="position: absolute; top: 0; left: 0; background-image:url(assets/img/login_background.png);background-repeat: no-repeat;background-size: contain; background-position:center; width: 100%; height: 100%;"    
-->

<div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
    <div class="row" style="position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background-color: aliceblue;">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">




            <div style="align-items: center;">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h2 class="modal-title">Авторизация</h2>
                        </div>
                        <div class="modal-body">
                            <div style="max-width: 430px;padding: 15px; margin: 0 auto;">
                                
                                <div class="form-group">
                                    <label>Логин</label>
                                    <input
                                      type="text"
                                      formControlName="login"
                                      class="form-control"
                                      [ngClass]="{ 'is-invalid': submitted && f['login'].errors }"
                                    />
                                    <div *ngIf="submitted && f['login'].errors" class="invalid-feedback">
                                      <div *ngIf="f['login'].errors['required']">Необходимо ввести имя учетной записи</div>
                                    </div>
                                  </div>

                                  <div class="form-group">
                                    <label>Пароль</label>
                                    <input
                                      type="password"
                                      formControlName="password"
                                      class="form-control"
                                      [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                                    />
                                    <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                      <div *ngIf="f['password'].errors['required']">Необходимо ввести пароль</div>                  
                                    </div>
                                  </div>

                                  <div *ngIf="message!=''" class="alert alert-danger" role="alert">
                                    {{message}}
                                  </div>
        
                            </div>
                        </div>

                       
                        <div class="modal-footer" style="align-items: center;">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Войти</button>                
                              </div>
                        </div>
                    </div>
                </div>
            </div>





      

        </form>
    </div>
</div>


<!--<div style="position: absolute; top: 0; left: 0; background-image:url(assets/img/login_background.png);background-repeat: no-repeat;background-size: cover; background-position:center; width: 100%; height: 100%;">
    <div class="row" style="position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);">
        <form>
            <div style="align-items: center;">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h2 class="modal-title">Вход</h2>
                        </div>
                        <div class="modal-body">
                            <div style="max-width: 430px;padding: 15px; margin: 0 auto;">
                                <input #login type="text" class="form-control" placeholder="Логин" autofocus=""
                                    style="margin-bottom:10px">
                                <input #password type="password" class="form-control" placeholder="Пароль">
                                <label style="margin-top:20px" *ngIf="message!=undefined">{{message}} </label>
                            </div>
                        </div>
                        <div class="modal-footer" style="align-items: center;">
                            <button class="btn btn-lg btn-primary"
                                (click)="Login($event, login.value, password.value)">Войти</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>-->

