import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Sort, MatSort } from '@angular/material/sort';
import { faCoffee, faShieldHalved, faPen, faEnvelope, faPhone, faShield, faBoxArchive, faEye, faTrash, faPlay } from '@fortawesome/free-solid-svg-icons';

import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { Project, VUsers } from '../../../model/structures';
import { MatPaginator } from '@angular/material/paginator';
import { MasterService } from 'src/app/services/master.service';

import { MatDialog } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
//import { UserService } from 'src/app/services';
import { AuthenticationService } from '../../../services/authentication.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CalRemoveConfirmComponent } from '../modal/cal-remove-confirm/cal-remove-confirm.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-calculation-list',
  templateUrl: './calculation-list.component.html',
  styleUrls: ['./calculation-list.component.css']
})
export class CalculationListComponent implements OnInit {
  public faCoffee = faCoffee;
  public faShieldHalved = faShieldHalved;
  public faPen = faPen;
  public faEnvelope = faEnvelope;
  public faPhone = faPhone;
  public faShield = faShield;
  public faBoxArchive = faBoxArchive;
  public faEye = faEye;
  public faTrash = faTrash;
  public faPlay = faPlay;
  //-------------------------------------------------
  displayedColumns: string[] = ['ID', 'ShortTitle', 'Module', 'Status', 'Edit', 'View', 'Remove', 'Launch'];
  dataSourceCalculation: any;
  calculation_data: any;
  selected_calculation: any;
  public ID!: number;
  //
  project_form: FormGroup;
  project_data!: Project;
  public security_data: any;
  public security_level_selected: any;
  //-------------------------------------------------
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  @ViewChild(MatSort) sort !: MatSort;

  constructor(
    private service: MasterService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private storageService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.project_form = this.formBuilder.group(
      {
        ID: new FormControl(''),
        ShortTitle: new FormControl(''),
        Description: new FormControl(''),
        SecurityLevel: new FormControl('', Validators.required)
      }
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.ID = params['id'];
      ///
      this.service.GetSecurityList().subscribe((result: any) => {
        this.security_data = result.data;
        let level = this.security_data[0];
        this.security_level_selected = level;
        //fill form
        this.project_form = this.formBuilder.group(
          {
            ID: new FormControl(''),
            ShortTitle: new FormControl(''),
            Description: new FormControl(''),
            SecurityLevel: new FormControl('', Validators.required)
          }
        );
        this.GetCalculationTable();
        this.GetProjectData();
      });
      ///      
    });
  }
  //------------------------------------------------------------------------------
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
  //------------------------------------------------------------------------------
  GetProjectData() {
    this.service.RedKeyGetProjectById(this.ID).subscribe((result: any) => {
      if (result.data != null) {
        this.storageService.saveToken(result.jwt);
        this.project_data = result.data[0];
        let level = this.security_data.filter((word: any) => word.ID === this.project_data.SecurityLevel)[0];
        this.security_level_selected = level;
        this.project_form.setValue(
          {
            ID: this.project_data.ID,
            ShortTitle: this.project_data.ShortTitle,
            Description: this.project_data.Description,
            SecurityLevel: level.ShortTitle
          }
        );
      }
      else {
        this.router.navigate(['login']);
      }
    });
  }
  //------------------------------------------------------------------------------
  Filterchange(event: Event) {
    const filvalue = (event.target as HTMLInputElement).value;
    this.dataSourceCalculation.filter = filvalue;
  }
  //------------------------------------------------------------------------------
  getrow(row: any) {
    this.selected_calculation = row;
    //console.log(row);
  }
  //------------------------------------------------------------------------------
  //------------------------------------------------------------------------------
  changeLevel(e: any) {
    this.Level?.setValue(e.target.value, {
      onlySelf: true,
    });
    let split_array: string[] = e.target.value.split(": ");
    this.security_level_selected = this.security_data[split_array[0]];
  }
  //------------------------------------------------------------------------------
  // Access formcontrols getter
  get Level() {
    return this.project_form.get('SecurityLevel');
  }
  //------------------------------------------------------------------------------
  RefreshList() {
    this.GetCalculationTable();
  }
  //------------------------------------------------------------------------------
  FunctionCreateCalculation() {
    this.router.navigate(['home/create_calc_master', { parent_id: this.ID }]);
  }
  //------------------------------------------------------------------------------
  FunctionEdit(_entity: any) {
    this.router.navigate(['home/edit_calc', { parent_id: this.ID, id: _entity.ID }]);
  }
  //------------------------------------------------------------------------------
  FunctionView(_entity: any) {
    this.router.navigate(['home/calc_view', { parent_id: this.ID, id: _entity.ID }]);
  }
  //------------------------------------------------------------------------------
  FunctionRemove(_entity: any) {
    console.log('');
    const dialogRef = this.dialog.open(CalRemoveConfirmComponent, {
      width: '450px',
      data: _entity,
    });
    //------------------------------------------------------------------------------
    dialogRef.afterClosed().subscribe(result => {
      if (result.submitted === true) {
        this.service.RedKeyRemoveCalculation(_entity.ID).subscribe((result: any) => {
          if (result.res == 'success') {
            this._snackBar.open('Сущность успешно удалена', '', {
              duration: 1000,
              panelClass: ['blue-snackbar']
            });
            this.GetCalculationTable();
          }
          else {
            this._snackBar.open('Ошибка сервера', '', {
              duration: 1000,
              panelClass: ['red-snackbar']
            })
          }
        },
          err0 => {
            console.log('error: ' + err0);
          }
        );
      }
    },
      err => {
        console.log('error: ' + err);
      });
  }
  //------------------------------------------------------------------------------
  FunctionLaunch(_entity: any) {
    this.service.RedKeyLaunchCalc(_entity.ID).subscribe((result: any) => {
      if (result.data != null) {
        console.log(result.data);
        this.GetCalculationTable();
      }
    });
  }
  //------------------------------------------------------------------------------
  //------------------------------------------------------------------------------
  GetCalculationTable() {
    this.service.RedKeyGetCalculations_by_parent_id(this.ID).subscribe((result: any) => {
      if (result.data != null) {
        this.storageService.saveToken(result.jwt);
        this.calculation_data = result.data;
        this.dataSourceCalculation = new MatTableDataSource<VUsers>(this.calculation_data);
        this.dataSourceCalculation.paginator = this.paginator;
        this.dataSourceCalculation.sort = this.sort;
      }
      else {
        this.router.navigate(['login']);
      }
    });
  }
  //------------------------------------------------------------------------------
  exit() {
    this.router.navigate(['home/project_cd']);
  }
  //------------------------------------------------------------------------------
  //------------------------------------------------------------------------------
  ngOnChanges() {
    this.GetCalculationTable();
    console.log('ngOnChanges calculations');
  }
  //------------------------------------------------------------------------------
  ngAfterViewInit() {

  }
  //---------------------------------------------------------------------------
  onSubmit() {
    console.log('submit');
    this.project_data.ShortTitle = this.project_form.value.ShortTitle;
    this.project_data.Description = this.project_form.value.Description;
    this.project_data.SecurityLevel = this.security_level_selected.ID;
    this.service.RedKeyUpdateProject(this.project_data).subscribe((result: any) => {
      if (result.res == 'success') {
        this._snackBar.open('Проект успешно изменен', '', {
          duration: 1000,
          panelClass: ['blue-snackbar']
        });
      }
      else {
        this._snackBar.open('Ошибка сервера', '', {
          duration: 1000,
          panelClass: ['red-snackbar']
        })
      }
    },
      err0 => {
        console.log('error: ' + err0);
      }

    );
  }

}
