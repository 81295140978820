<div style="height: 94vh;background-color: aliceblue;">
    <div style="width: 100%; height: 100%;">

        <h1>Создать паспорт КНТП</h1>


        <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selected_tab">
            <mat-tab label="Общие положения">
                <!------>
                <form [formGroup]="passport_common_form" (ngSubmit)="onSubmit()" autocomplete="off">
                    <!--ShortTitle-->
                    <div class="form-group">
                        <label>Название</label>
                        <input type="text" formControlName="ShortTitle" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['ShortTitle'].errors }" />
                        <div *ngIf="submitted && f['ShortTitle'].errors" class="invalid-feedback">
                            <div *ngIf="f['ShortTitle'].errors['required']">Поле не может быть пустым
                            </div>
                        </div>
                    </div>
                    <!--Goal-->
                    <div class="form-group">
                        <label>Цель КНТП</label>
                        <input name="Goal" formControlName="Goal" class="form-control">
                        <div *ngIf="submitted && f['Goal'].errors" class="invalid-feedback">
                            <div *ngIf="f['Goal'].errors['required']">Поле не может быть пустым
                            </div>
                        </div>
                    </div>
                    <!--Type-->
                    <div class="form-group">
                        <label>Тип КНТП</label>
                        <!--<input type="text" formControlName="Type" class="form-control" />-->
                        <input name="Type" formControlName="Type" class="form-control">
                    </div>
                    <!--SecurityLevel-->
                    <div class="form-group">
                        <label>Уровень секретности</label>
                        <select class="form-control" (change)="changeLevel($event)" formControlName="SecurityLevel">
                            <option *ngFor="let level of security_data" [ngValue]="level.Level">
                                {{ level.ShortTitle }}
                            </option>
                        </select>
                    </div>
                    <!--DateInitiation-->
                    <div class="form-group">
                        <label>Дата инициации КНТП</label>
                        <!--<input name="DateInitiation" matInput formControlName="DateInitiation"
                            [matDatepicker]="picker_initiation_date" placeholder="{{'datePlaceholder' | translate}}">-->
                        <input name="DateInitiation" matInput formControlName="DateInitiation"
                            [matDatepicker]="picker_initiation_date" placeholder="Дата">
                        <mat-datepicker-toggle matSuffix [for]="picker_initiation_date"></mat-datepicker-toggle>
                        <mat-datepicker #picker_initiation_date>{{date_init}}</mat-datepicker>
                        <div *ngIf="submitted && f['DateInitiation'].errors" class="invalid-feedback">
                            <div *ngIf="f['DateInitiation'].errors['required']">Поле не может быть пустым
                            </div>
                        </div>
                    </div>
                    <!--Document-->
                    <div class="form-group">
                        <label>Распорядительные документы</label>
                        <textarea name="Document" cols="40" rows="2" formControlName="Document"
                            class="form-control"></textarea>
                    </div>
                    <!--Members-->
                    <div class="form-group">
                        <label>Основные участники проекта</label>
                        <textarea name="Members" cols="40" rows="2" formControlName="Members"
                            class="form-control"></textarea>
                    </div>
                    <!--StartDate -->
                    <div class="form-group">
                        <label>Сроки реализации КНТП</label>
                        <span>
                            <input name="StartDate" matInput formControlName="StartDate"
                                [matDatepicker]="picker_start_date" placeholder="Начало">
                            <mat-datepicker-toggle matSuffix [for]="picker_start_date"></mat-datepicker-toggle>
                            <mat-datepicker #picker_start_date></mat-datepicker>
                            <div *ngIf="submitted && f['StartDate'].errors" class="invalid-feedback">
                                <div *ngIf="f['StartDate'].errors['required']">Поле не может быть пустым
                                </div>
                            </div>
                        </span>

                        <span>
                            ...
                        </span>

                        <span>
                            <input name="EndDate" matInput formControlName="EndDate" [matDatepicker]="picker_end_date"
                                placeholder="Завершение">
                            <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
                            <mat-datepicker #picker_end_date></mat-datepicker>
                            <div *ngIf="submitted && f['EndDate'].errors" class="invalid-feedback">
                                <div *ngIf="f['EndDate'].errors['required']">Поле не может быть пустым
                                </div>
                            </div>
                        </span>

                    </div>

                    <!--EndDate--
                    <div class="form-group">
                        <input name="EndDate" matInput formControlName="EndDate" [matDatepicker]="picker_end_date" placeholder="{{'datePlaceholder' | translate}}">
                        <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
                        <mat-datepicker #picker_end_date></mat-datepicker>
                    </div>-->

                    <!--Costs-->
                    <div class="form-group">
                        <label>Costs</label>
                        <input name="Costs" type="number" formControlName="Costs" class="form-control">
                    </div>
                </form>
                <!------>
            </mat-tab>
            <mat-tab label="Целевые характеристики">

                <div class="mat-elevation-z8">
                    <!---------------->
                    <mat-toolbar>
                        <span>
                            <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
                                (click)="GetTargetedTable()">
                                <mat-icon>refresh</mat-icon>
                            </button>
                        </span>

                        <span>
                            <button mat-icon-button aria-label="Example icon-button with a menu"
                                (click)="AddTargeted()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </span>

                        <span class="flexExpand"></span>


                    </mat-toolbar>

                    <!--------Targeted table-->
                    <div class="mat-elevation-z8">
                        <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <input matInput placeholder="Filter" (keyup)="Filterchange($event)">
                        </mat-form-field>

                        <table mat-table matSort [dataSource]="dataSourceTargeted" class="mat-elevation-z8">

                            <!-- ID Column -->
                            <ng-container matColumnDef="ID">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
                                <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                            </ng-container>

                            <!-- TypeTargeted Level -->
                            <ng-container matColumnDef="TypeTargeted">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                    Тип
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.TypeTargeted}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>Название</th>
                                <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                            </ng-container>

                            <!-- Volume Column -->
                            <ng-container matColumnDef="Volume">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>Значение</th>
                                <td mat-cell *matCellDef="let element"> {{element.Volume}} </td>
                            </ng-container>

                            <!-- Remove Column -->
                            <ng-container matColumnDef="Remove">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <button (click)="FunctionRemove(element)" mat-button>
                                        <fa-icon [icon]="faTrash" style="color: darkred;"></fa-icon>
                                    </button>
                                </td>

                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsTargeted"></tr>
                            <tr (click)="getrow(row)" mat-row *matRowDef="let row; columns: displayedColumnsTargeted;">
                            </tr>
                        </table>

                        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5,10,25,100]" showFirstLastButtons>

                        </mat-paginator>

                    </div>

                </div>
                <!-------------------->
            </mat-tab>
            <mat-tab label="Реестр технологий, входящих в КНТП">
                <!---------------->
                <div class="mat-elevation-z8">
                    <!---------------->
                    <mat-toolbar>
                        <span>
                            <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
                                (click)="GetRegistryTable()">
                                <mat-icon>refresh</mat-icon>
                            </button>
                        </span>

                        <span>
                            <button mat-icon-button aria-label="Example icon-button with a menu"
                                (click)="SelectRegistry()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </span>

                        <span *ngIf="selected_registry_array.length>0">
                            <button mat-raised-button [disabled]="selected_registry_array.length===0"
                                (click)="AddRegistry()">
                                Добавить паспортов, шт.: {{selected_registry_array.length}}
                            </button>
                        </span>



                        <span class="flexExpand"></span>

                        <span>
                            <mat-slide-toggle class="example-margin" [(ngModel)]="show_archieved"
                                (change)="GetRegistryTable()">Показать
                                архивные</mat-slide-toggle>
                        </span>
                    </mat-toolbar>

                    <!--------RegistryList table-->
                    <div class="mat-elevation-z8">
                        <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <input matInput placeholder="FilterRegistry" (keyup)="FilterchangeRegistry($event)">
                        </mat-form-field>

                        <table mat-table matSort [dataSource]="dataSourceRegistry" class="mat-elevation-z8">

                            <!-- ID Column -->
                            <ng-container matColumnDef="ID">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
                                <td mat-cell *matCellDef="let element_table"> {{element_table.ID}} </td>
                            </ng-container>

                            <!-- ShortTitle -->
                            <ng-container matColumnDef="ShortTitle">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                    Название
                                </th>
                                <td mat-cell *matCellDef="let element_table"> {{element_table.ShortTitle}} </td>
                            </ng-container>

                            <!-- SecurityLevel Column -->
                            <ng-container matColumnDef="SecurityLevel">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                    <fa-icon [icon]="faShieldHalved"></fa-icon>
                                </th>
                                <td mat-cell *matCellDef="let element_table"> {{element_table.SecurityLevel}} </td>
                            </ng-container>

                            <!-- ArchiveSign Column -->
                            <ng-container matColumnDef="ArchiveSign">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                    <fa-icon [icon]="faBoxArchive"></fa-icon>
                                </th>
                                <td mat-cell *matCellDef="let element_table">
                                    <mat-checkbox class="example-margin" [(ngModel)]="element_table.ArchiveSign"
                                        [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- Remove Column -->
                            <ng-container matColumnDef="Remove">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </th>
                                <td mat-cell *matCellDef="let element_table">
                                    <button (click)="FunctionRemoveRegistry(element_table)" mat-button>
                                        <fa-icon [icon]="faTrash" style="color: darkred;"></fa-icon>
                                    </button>
                                </td>

                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsRegistryList"></tr>
                            <tr (click)="getrowRegistry(row)" mat-row
                                *matRowDef="let row; columns: displayedColumnsRegistryList;"></tr>
                        </table>

                        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5,10,25,100]" showFirstLastButtons>

                        </mat-paginator>

                    </div>

                </div>
                <!---------------->
            </mat-tab>
        </mat-tab-group>



        <div style="display: flex;">
            <button mat-raised-button color="primary" (click)="save()" style="margin-right: 5px;">Сохранить</button>
            <button mat-raised-button color="warn" (click)="exit()" style="margin-left: 5px;">Отмена</button>
        </div>
        

    </div>
</div>