<div style="height: 94vh;">


    <div style="width: 100%; height: 100%;">

        <div style="display: flex; align-items: center;">
            <fa-icon [icon]="faGem" style="font-size: 24px; margin-left: 10px;"></fa-icon>
            <h1 style="margin-left: 10px; margin-bottom: 0;">КНТП</h1>
        </div>
        
                
        <mat-toolbar>
            <span>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
                    (click)="GetRootList()" matTooltip="Обновить содержимое страницы">
                    <mat-icon>refresh</mat-icon>
                </button>
            </span>

            <span>
                <button mat-icon-button aria-label="Создать новый паспорт КНТП" (click)="FunctionCreatePassport()" matTooltip="Добавить содержимое страницы">
                    <mat-icon>add</mat-icon>
                </button>
            </span>

            <span class="flexExpand"></span>



            <span>
                <mat-slide-toggle class="example-margin" [(ngModel)]="show_archieved" (change)="GetRootList()">Показать
                    архивные
                </mat-slide-toggle>
            </span>

        </mat-toolbar>

        <hr>


        <mat-accordion class="example-headers-align" multi>

            <mat-expansion-panel *ngFor="let item of passport_list">
                <mat-expansion-panel-header class="right-aligned-header">

                    <mat-panel-title>
                        <fa-icon [icon]="faGem"></fa-icon>
                    </mat-panel-title>


                    <mat-panel-title>
                        {{item.ID}}
                    </mat-panel-title>


                    <mat-panel-title>
                        {{item.ShortTitle}}
                    </mat-panel-title>

                    <mat-panel-description>
                        {{item.SecurityLevel}}
                    </mat-panel-description>

                    <span style="display: block; margin-top: 15px;">
                        <mat-checkbox class="example-margin" [(ngModel)]="item.ArchiveSign" (change)="FunctionChangeActive(item)" matTooltip="Архивировать элемент">
                        </mat-checkbox>
                    </span>
                    

                    <span style="display: block; margin-top: 10px;">
                        <button mat-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="FunctionEdit(item)" matTooltip="Редактировать элемент">
                            <mat-icon style="color: darkred; font-size: 18px;">edit</mat-icon>
                        </button>
                    </span>
                    
                    
                </mat-expansion-panel-header>

                <mat-list>
                    <div mat-subheader>Общие положения</div>
                    <mat-list-item>
                        <div style="width: 100%;" class="row">
                            <div class="col-4">Цель КНТП</div>
                            <div class="col-8">{{item.Goal}}</div>
                        </div>
                    </mat-list-item>

                    <mat-list-item>
                        <div style="width: 100%;" class="row">
                            <div class="col-4">Тип КНТП</div>
                            <div class="col-8">{{item.Type}}</div>
                        </div>
                    </mat-list-item>
         

                    <mat-list-item>
                        <div style="width: 100%;" class="row">
                            <div class="col-4">Дата инициации КНТП</div>
                            <div class="col-8">{{item.DateInitiation}}</div>
                        </div>
                    </mat-list-item>

                    <mat-list-item>
                        <div style="width: 100%;" class="row">
                            <div class="col-4">Распорядительные документы (решение НТС приказ ГД)</div>
                            <div class="col-8">{{item.Document}}</div>
                        </div>
                    </mat-list-item>

                    <mat-list-item>
                        <div style="width: 100%;" class="row">
                            <div class="col-4">Основные участники проекта</div>
                            <div class="col-8">{{item.Members}}</div>
                        </div>
                    </mat-list-item>

                    <mat-list-item>
                        <div style="width: 100%;" class="row">
                            <div class="col-4">Срок реализации КНТП</div>
                            <div class="col-8">{{item.StartDate}}...{{item.EndDate}}</div>
                        </div>
                    </mat-list-item>

                    <mat-list-item>
                        <div style="width: 100%;" class="row">
                            <div class="col-4">Ожидаемый объем затрат (млн.руб.)</div>
                            <div class="col-8">{{item.Costs}}</div>
                        </div>
                    </mat-list-item>

                </mat-list>

            </mat-expansion-panel>


        </mat-accordion>

    </div>


</div>