import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cstp-common',
  templateUrl: './cstp-common.component.html',
  styleUrls: ['./cstp-common.component.css']
})
export class CstpCommonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
