// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    srv_mode:2,
    servers:[
          {
              'host':'http://localhost:3000'
          },
          {
              'host':'http://192.168.16.16:3000'
          },
          {
              'host':'https://asptn-01.egorychaero.ru'
          },  
      ],
      kpvm_url: "http://192.168.16.11:3110/"
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  