<div style="height: 94vh;">
    <div style="width: 100%; height: 100%;">
        <h1>
            <fa-icon [icon]="faGem"></fa-icon>Концептуальный проект
        </h1>

        <mat-toolbar>
            <span>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
                        (click)="GetRootList()" matTooltip="Обновить содержимое страницы">
                    <mat-icon>refresh</mat-icon>
                </button>
            </span>

            <span>
                <button mat-icon-button aria-label="Создать новый паспорт КНТП" (click)="FunctionCreateProject()" matTooltip="Добавить содержимое страницы">
                    <mat-icon>add</mat-icon>
                </button>
            </span>
            

            <span class="flexExpand"></span>

            <span>
                <mat-slide-toggle class="example-margin" [(ngModel)]="show_archieved" (change)="GetRootList()">
                    Показать архивные
                </mat-slide-toggle>
            </span>
            
        </mat-toolbar>

        <hr>

        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel *ngFor="let item of project_list">
                <mat-expansion-panel-header class="right-aligned-header">
                    <mat-panel-title>
                        <fa-icon [icon]="faGem"></fa-icon>
                    </mat-panel-title>

                    <mat-panel-title>
                        {{item.ID}}
                    </mat-panel-title>

                    <mat-panel-title>
                        {{item.ShortTitle}}
                    </mat-panel-title>

                    <mat-panel-description>
                        {{item.SecurityLevel}}
                    </mat-panel-description>

                    <span style="margin-top:16px; display: inline-block; position: relative; top: -5px;">
                        <mat-checkbox class="example-margin" [(ngModel)]="item.ArchiveSign"
                                      (change)="FunctionChangeActive(item)" matTooltip="Архивировать элемент" aria-label="Архивировать элемент">
                        </mat-checkbox>
                    </span>

                    <button mat-button class="example-icon" aria-label="Edit icon-button"
                            (click)="FunctionEdit(item)" style="font-size: 18px; color: darkred;" matTooltip="Редактировать элемент" aria-label="Редактировать элемент">
                        <i class="material-icons" style="font-size: 18px; margin-top: 6px;">create</i>
                    </button>
                </mat-expansion-panel-header>

                {{item.Description}}
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
