<nav class="navbar navbar-default justify-content-between" style="background-color: #D1DEDF;">
  
    <a class="navbar-brand">
      <button mat-button [routerLink]="['/home']"><img [src]="LogoPath"></button>
    </a>
    <div>
      <h1 style="color: #2D716D;">ПТН "Скорость"</h1>
    </div>
    <form class="form-inline">      
      <button class="btn btn-outline-ligth my-2 my-sm-0" [routerLink]="['/login']">Выход</button>
    </form>
  </nav>

<div style="height: 94vh;">


    <div style="width: 100%; height: 100%;background-color: aliceblue;">
        <h1>
            Ошибка!
        </h1>

        <p style="text-align:center;">
            <img [src]="fullImagePath">
        </p>


    </div>

    <!--
    
-->

</div>