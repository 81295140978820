
    <div style="align-items: center;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
      
              <div style="min-height: 90%;max-height: 90%; max-width: 90%; margin: 0 auto;">
                
                <div (click)="onSubmit()">
                  <img [src]="img_full" class="preview" style="max-width: 100%; max-height: 100%;cursor: zoom-out;">
                </div>
        
              </div>
            </div>
      
          </div>
        </div>
      </div>
      