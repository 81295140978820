<div class="projects-menu">
    <h2>Меню проектов</h2>
  
    <div class="controls">
      <button (click)="openCreateEditForm()">Создать новый проект</button>
  
      <label style="display: flex; align-items: center;">
        <input type="checkbox" [(ngModel)]="showArchived" (change)="onShowArchivedChange()" style="margin-right: 0.5rem;" />
        Архивированные проекты
      </label>
    </div>
  
    <table>
      <thead>
        <tr>
          <th>ИД</th>
          <th>Краткое наименование</th>
          <th>Описание</th>
          <th>Уровень секретности</th>
          <th>Архивировать</th>
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let project of filteredProjects">
          <td>{{ project.id }}</td>
          <td>{{ project.shortName }}</td>
          <td>{{ project.description }}</td>
          <td>{{ project.securityLevel }}</td>
          <td>
            <input type="checkbox" [(ngModel)]="project.archived" (change)="onArchiveChange(project)" />
          </td>
          <td><button (click)="editProject(project)">Редактировать</button></td>
        </tr>
      </tbody>
    </table>
  
    <app-project-create-edit
      *ngIf="showEditForm"
      [project]="selectedProject"
      (close)="onCloseForm($event)">
    </app-project-create-edit>
  </div>
  