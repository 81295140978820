<div style="height: 94vh;">


    <div style="width: 100%; height: 100%;">
        <as-split direction="horizontal">
            <as-split-area [size]="20">

               

            </as-split-area>

            <as-split-area [size]="80">               
                Загруженность стендов
            </as-split-area>

        </as-split>
    </div>


</div>