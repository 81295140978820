<div style="height: 94vh;">


    <div style="width: 100%; height: 100%;">
        <as-split direction="horizontal">
            <as-split-area [size]="20">

                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <!-- This is the tree node template for leaf nodes -->
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding style="cursor: pointer;" 
                    [ngStyle]="{'background-color': (node.ID===selected_row.ID) ? 'Lavender' : 'white' }"
                    (click)="click_tree(node)">
                        <!-- use a disabled button to provide padding for tree leaf -->
                        <button mat-icon-button disabled></button>
                        {{node.name}}
                    </mat-tree-node>
                    <!-- This is the tree node template for expandable nodes -->
                    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding style="cursor: pointer;" [ngStyle]="{'background-color': (node.ID===selected_row.ID) ? 'Lavender' : 'white' }"
                        (click)="click_tree(node)">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        {{node.name}}
                    </mat-tree-node>
                </mat-tree>

            </as-split-area>

            <as-split-area *ngIf="page_mode=='Users'" [size]="80">
                <as-split direction="vertical">
                    <as-split-area [size]="50">
                        <app-users #child [parent_data]="selected_row"></app-users>
                    </as-split-area>
                </as-split>
            </as-split-area>
            
            <as-split-area *ngIf="page_mode=='Groups'" [size]="80">
                <!--<app-administration-list  #child [parent_id]="custom_table"></app-administration-list>-->
                <app-administration-list  #child [parent_data]="selected_row" (UpdateEvent)="UpdateRoot($event)"></app-administration-list>
            </as-split-area>
<!--
            <as-split-area *ngIf="page_mode=='Groups'" [size]="80">
                <app-groups  #child [parent_id]="custom_table"></app-groups>
            </as-split-area>
        -->

        </as-split>
    </div>

    <!--
    
-->

</div>