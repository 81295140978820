<div style="height: 94vh; background-color: aliceblue;">
    <div style="width: 100%; height: 100%;">
  
      <h1>Редактировать сущность</h1>
  
      <mat-form-field appearance="fill" class="full-width-field">
        <mat-label>Базовый шаблон</mat-label>
        <input matInput [value]="selected_template.ShortTitle" readonly>
      </mat-form-field>
  
      <div class="row">
        <div class="col-10">
          <div>
            <form [formGroup]="form" (ngSubmit)="submit()">
              <formly-form [model]="model_header" [fields]="fields" [options]="options" [form]="form"></formly-form>
            </form>
          </div>
        </div>
  
        <div class="col-2" (click)="show_full()">
          <img id="preview" src="{{ img_path }}" style="object-fit: cover; max-width: 95%; max-height: 95%; background-color: gray; cursor: zoom-in;">
        </div>
      </div>
  
      <div *ngIf="selected_template && fields && fields_body">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
          <mat-tab label="Знания">
            <div>
              <form [formGroup]="form_body" (ngSubmit)="submit()">
                <formly-form [model]="model_body" [fields]="fields_body" [options]="options_body" [form]="form_body"></formly-form>
              </form>
            </div>
          </mat-tab>
  
          <!-- Вкладка "Управление файлами" -->
          <mat-tab label="Управление файлами">
            <!-- Секция управления файлами -->
            <div class="file-management-section">
              <h2>Управление файлами</h2>
  
              <!-- Форма загрузки нового файла -->
              <div class="upload-section">
                <!-- Кастомная кнопка выбора файла -->
                <button mat-raised-button color="primary" (click)="fileInput.click()">
                  <mat-icon>attach_file</mat-icon>
                  Выберите файл
                </button>
  
                <!-- Отображение имени выбранного файла -->
                <span *ngIf="selectedFileName">{{ selectedFileName }}</span>
  
                <!-- Поле для отображаемого имени файла -->
                <mat-form-field class="upload-field" appearance="fill">
                  <mat-label>Отображаемое имя</mat-label>
                  <input
                    matInput
                    [(ngModel)]="shortTitle"
                    placeholder="Введите имя файла"
                    name="shortTitle"
                  />
                </mat-form-field>
  
                <!-- Кнопка загрузки файла -->
                <button mat-raised-button color="primary" (click)="uploadFile()" [disabled]="!selectedFile">
                  <mat-icon>cloud_upload</mat-icon>
                  Загрузить
                </button>
  
                <!-- Скрытый input для выбора файла -->
                <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" />
              </div>
  
              <!-- Таблица списка файлов -->
              <table mat-table [dataSource]="dataSourceFiles" class="mat-elevation-z8">
  
                <!-- Столбец имени файла -->
                <ng-container matColumnDef="ShortTitle">
                  <th mat-header-cell *matHeaderCellDef>Имя файла</th>
                  <td mat-cell *matCellDef="let file">
                    <mat-icon color="accent">insert_drive_file</mat-icon>
                    {{ file.ShortTitle }}
                  </td>
                </ng-container>
  
                <!-- Столбец действий -->
                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef>Действия</th>
                  <td mat-cell *matCellDef="let file">
                    <button mat-icon-button color="primary" (click)="downloadFile(file.ID, file.ShortTitle, file.URL)" matTooltip="Скачать">
                      <mat-icon>cloud_download</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="deleteFile(file.ID)" matTooltip="Удалить">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedFileColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedFileColumns;"></tr>
              </table>
  
              <!-- Пагинация -->
              <mat-paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
  
              <!-- Сообщение при отсутствии файлов -->
              <div *ngIf="files.length === 0" class="no-files">
                <p>Файлы отсутствуют.</p>
              </div>
            </div>
          </mat-tab>
  
        </mat-tab-group>
      </div>
  
      <!-- Кнопки сохранения и выхода -->
      <div class="action-buttons">
        <button mat-raised-button color="primary" (click)="save()">Сохранить</button>
        <button mat-raised-button color="warn" (click)="exit()" style="margin-left: 10px;">Отмена</button>
      </div>
  
    </div>
  </div>
  