<as-split direction="vertical">
    <as-split-area [size]='panel_size_top'>

        <h1>
            <!--<fa-icon [icon]="faUser"></fa-icon>-->Виртуальные испытательные стенды
        </h1>

        <mat-divider></mat-divider>

        <mat-toolbar>
            <span>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
                        (click)="GetAll()" matTooltip="Обновить содержимое страницы">
                    <mat-icon>refresh</mat-icon>
                </button>
            </span>
            
            <span class="flexExpand"></span>

            <span>
                <mat-slide-toggle class="example-margin" [(ngModel)]="show_archieved" (change)="GetAll()">
                    Показать архивные
                </mat-slide-toggle>
            </span>
            
        </mat-toolbar>
        <mat-divider></mat-divider>

        <div class="mat-elevation-z8">

            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput placeholder="Filter" (keyup)="Filterchange($event)">
            </mat-form-field>

            <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

                <!-- ID Column -->
                <ng-container matColumnDef="ID">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                </ng-container>

                <!-- ShortTitle Column -->
                <ng-container matColumnDef="ShortTitle">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Название (Имя)</th>
                    <td mat-cell *matCellDef="let element"> {{element.ShortTitle}} </td>
                </ng-container>

                <!-- TypeEntity Column -->
                <ng-container matColumnDef="TypeEntity">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Тип</th>
                    <td mat-cell *matCellDef="let element">
                        <span style="color: black; font-size: 18px;"></span>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="ArchiveSign">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <fa-icon [icon]="faBoxArchive"></fa-icon>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox class="example-margin" [(ngModel)]="element.ArchiveSign"
                            (change)="FunctionChangeActive(element)" [disabled]="true" matTooltip="Архивировать элемент"></mat-checkbox>
                    </td>
                </ng-container>

                <!--Function Edit-->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                        <fa-icon [icon]="faPen"></fa-icon>
                    </th>
                
                    <td mat-cell *matCellDef="let element" style="text-align: center;">
                        <button (click)="FunctionEdit(element)" mat-button [disabled]="true" style="font-size: 14px; color: darkred;"
                                matTooltip="Редактировать элемент">
                            <i class="material-icons" style="font-size: 18px;">edit</i>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr (click)="getrow(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5,10,25,100]" showFirstLastButtons>

            </mat-paginator>

        </div>

    </as-split-area>

    <as-split-area [size]="50" *ngIf="selected_item!=undefined">
        <app-csv-list [parent_data]="selected_item"></app-csv-list>
        <!--
        <app-groups-table *ngIf="selected_item.TypeEntity==='Groups'" [ID]="selected_item.ID"></app-groups-table>

        <app-users-table *ngIf="selected_item.TypeEntity==='Users'" [ID]="selected_item.ID"></app-users-table>
    -->
    </as-split-area>


</as-split>
